let checkUser = () => {
  let admin = JSON.parse(localStorage.getItem("admin"));
  if (admin) {
    return true;
  } else {
    return false;
  }
};

export default {
  baseURL: process.env.VUE_APP_URL,
  gMapAPIKey: process.env.VUE_APP_GMAP_API_KEY,
  versionNumber: "v1.3.0.27",
  userSite: process.env.VUE_APP_USER_SITE,
  checkUser: checkUser
};
